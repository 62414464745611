// import logo from './logo.svg';
import React from 'react';
import {
    Outlet,
    Link, useLocation
} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRightFromBracket , faRightToBracket } from '@fortawesome/free-solid-svg-icons'
import { useAppStateContext} from "../../store/contexts";
import {useLoginLogoutGuard} from "../../hooks/useLoginLogoutGuard";
import {createLoginAction, createLogoutAction} from "../../store/actions";


function HomePage() {
    const {pathname} = useLocation();
    const {appState,dispatch} = useAppStateContext();
    function login(){
        dispatch(createLoginAction("alzamer"))
    }
    function logout(){
        dispatch(createLogoutAction())
    }
    useLoginLogoutGuard();

    let tabs = <></>;
    if(appState.loggedInUser){
        tabs = <div className="hero-foot">
            <nav className="tabs is-boxed is-fullwidth">
                <div className="container">
                    <ul>
                        <li className={pathname==="/UploadDocuments"?"is-active":""}><Link to={"/UploadDocuments"}>Upload Documents</Link></li>
                        <li className={pathname==="/InputDocument"?"is-active":""}><Link to={"/InputDocument"}>Input Documents</Link></li>
                        <li className={pathname==="/StagingDocument"?"is-active":""}><Link to={"/StagingDocument"}>Staging Documents</Link></li>
                        <li className={pathname==="/OutputDocument"?"is-active":""}><Link to={"/OutputDocument"}>Output Documents</Link></li>
                    </ul>
                </div>
            </nav>
        </div>;
    }
    return (
        <div className="App">
            <section className="hero is-dark ">
                <div className="hero-head">
                    <header className="navbar">
                        <div className="container is-fluid ">
                            {/*https://bulma.io/documentation/layout/hero/*/}
                            <div className="navbar-menu">
                                <div className="navbar-end">
                                    <span className="navbar-item" onClick={!appState.loggedInUser ? login : logout}>
                                      <button className="button is-dark is-inverted" >
                                        <span className="icon">
                                            {/*<FontAwesomeIcon icon={faGithub} />*/}
                                            <FontAwesomeIcon
                                                icon={!appState.loggedInUser ? faRightToBracket : faRightFromBracket}/>
                                            {/*<i className="fa-brands fa-github"></i>*/}
                                        </span>
                                        <span>{!appState.loggedInUser ? "login" : "logout"}</span>
                                      </button>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </header>
                </div>

                <div className="hero-body">
                    <div className="container has-text-centered">
                        <p className="title">
                            Document Processing
                        </p>
                        <p className="subtitle">
                            using AWS AI service - Textract
                        </p>
                    </div>
                </div>

                {tabs}
            </section>
            <br/>

            <main className="container">
                <Outlet/>
            </main>
        </div>

    );
}

export default HomePage;
