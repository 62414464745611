import {produce} from "immer";
import {ListOutputDocumentsResponse, S3PresignedListResponse, S3PresignedPostResponse} from "../services/S3";
import {createContext, useContext} from "react";
import {Action, ActionType, ListFolderExecutedPayload, ListFolderPresignedUrlPreparedPayload} from "./actions";


export enum S3FolderNames{
    INPUT="input",
    STAGING="staging",
    OUTPUT="output"
}
interface S3Folder{
    name: S3FolderNames,
    presignedListUrl:string|null,
    loading:boolean,
    files: ListOutputDocumentsResponse | null
}
export interface AppState{
    loggedInUser: string | null
    presignedPostInfo: S3PresignedPostResponse | null,
    s3Folders:{
        [index in S3FolderNames]: S3Folder|null
    }
}

export const InitAppState:AppState={
    loggedInUser:null,
    presignedPostInfo: null,
    s3Folders:{
        [S3FolderNames.INPUT]: null,
        [S3FolderNames.STAGING]: null,
        [S3FolderNames.OUTPUT]: null
    }

}






export function AppStateReducer(state: AppState, action: Action){
    console.info("reducing action", action.type)
    switch (action.type){
        case "PRESIGNED_POST_INFO_PREPARED": return produce(state, draft => {
            draft.presignedPostInfo = action.payload ;
        });
        case "PRESIGNED_POST_INFO_EXPIRED": return produce(state, draft => {
            draft.presignedPostInfo = null;
        });
        case "LIST_FOLDER_PRESIGNED_URL_PREPARED":return produce(state, draft => {
            // const temp =(action.payload as ListFolderPresignedUrlPreparedPayload );
            draft.s3Folders[action.payload.folder] = {
                name: action.payload.folder,
                loading:false,
                presignedListUrl:action.payload.s3PresignedListResponse.url,
                files:null
            };
            console.info("added url for ", action.payload.folder)
        });
        case "LIST_FOLDER_REQUESTED":return produce(state, draft => {
            // const temp =(action.payload as S3FolderNames );
            const folder = draft.s3Folders[action.payload];
            if(folder){
                folder.loading = !!folder;
            }
            console.info("requested files for ", action.payload)
        });
        case "LIST_FOLDER_COMPLETED":return produce(state, draft => {
            // const temp =(action.payload as ListFolderExecutedPayload );
            const folder = draft.s3Folders[action.payload.folder];
            if(folder){
                folder.files = action.payload.s3PresignedListResponse ;
                folder.loading=false;
                console.info("added files for ", action.payload.folder)
            }
        });
        case "LIST_FOLDER_PRESIGNED_URL_EXPIRED": return produce(state, draft => {
            const folder = draft.s3Folders[action.payload];
            if(folder) {
                folder.presignedListUrl = null;
                console.info("removed url for ", action.payload)
            }
        });
        case "LOGIN": return produce(state, draft => {
            draft.loggedInUser = action.payload ;
        });
        case "LOGOUT": return InitAppState;
        default : return state;
    }

}
