import React, {useEffect, useRef, useState} from 'react';
import './UploadDocument.css';
import { getPresignedPostUrl, S3PresignedPostResponse} from '../../services/S3';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUpload  } from '@fortawesome/free-solid-svg-icons'
import {useAppStateContext} from "../../store/contexts";
import {
    createListFolderPresignedUrlExpiredAction,
    createPresignedPostInfoExpiredAction,
    createPresignedPostInfoPreparedAction
} from "../../store/actions";

interface UploadDocumentProps{
    user: string
}
function UploadDocument({user}: UploadDocumentProps) {
    const {appState,dispatch} = useAppStateContext();

    const [fileName, setFileName] = useState<string>("")
    const[showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false)
    const[showProgressBar,setShowProgressBar] = useState<boolean>(false)

    const fileInputField = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if(!showSuccessMessage) return;

        const id = setTimeout(()=>{ setShowSuccessMessage(false) },4000);
        return ()=> clearTimeout(id);

    }, [showSuccessMessage]);



    async function prepareFOrm(){
        const postResponse = await getPresignedPostUrl(user);
        dispatch(createPresignedPostInfoPreparedAction(postResponse));
        setTimeout(() => {
            dispatch(createPresignedPostInfoExpiredAction());
        }, Math.max(postResponse.expiresInSeconds -
            60,0) * 1000);
    }

    if(!appState.presignedPostInfo){
        return (
            <section className="UploadDocument">
                <h2 className="is-capitalized">form to upload documents</h2>
                <button className="button is-dark is-fullwidth my-2 is-capitalized" onClick={prepareFOrm}> prepare form</button>
            </section>
        )
    }
    let uploadSuccessfully=<></>
    if(showSuccessMessage){
         uploadSuccessfully=<div className="notification is-success is-light  my-5">
            {/*<button className="delete"></button>*/}
            Document was uploaded successfully.
        </div>
    }

    async function onFormSubmit(event: React.FormEvent<HTMLFormElement>){
        event.preventDefault();
        let file = fileInputField.current?.files?.item(0);
        if(!file){
            return;
        }

        console.info(event)
        let form = event.currentTarget;

        const data = new FormData()

        const presignedPostInfo = appState.presignedPostInfo;
        if(!presignedPostInfo){
            return;
        }
        Object.keys(presignedPostInfo.fields).forEach(field =>
            data.append(field, presignedPostInfo.fields[field])
        );
        // data.append('file', input?.files[0])
        data.append('file', file);

        // data.append('user', 'hubot')
        setShowProgressBar(true);
        const response =await fetch(presignedPostInfo.url, {
            method: 'POST',
            body: data
        })
        console.info("done")
        setShowProgressBar(false);
        if (response.status>=200 && response.status<300 ){
            form.reset();
            setFileName("");
            setShowSuccessMessage(true);
        }
        // setFileName(event.currentTarget?.files?.item(0)?.name??"");

    }
    function onFileUpload(event: React.FormEvent<HTMLInputElement>){
        setFileName(event.currentTarget?.files?.item(0)?.name??"");
    }

    return (
        <section className="UploadDocument">
            <h2 className="is-capitalized">upload documents form</h2>
            {uploadSuccessfully}
            {showProgressBar &&<progress className="progress is-small is-dark" max="100">15%</progress>}
            <form method="POST" action={appState.presignedPostInfo?.url} encType="multipart/form-data" onSubmit={onFormSubmit}>

                <div className="file has-name is-dark is-boxed is-centered">
                    <label className="file-label">
                        <input required={true} ref={fileInputField} className="file-input" type="file" name="file" onChange={onFileUpload}/>
                        <span className="file-cta">
                          <span className="file-icon">
                              <FontAwesomeIcon   icon={faUpload} />
                          </span>
                          <span className="file-label">
                            Choose a file…
                          </span>
                        </span>
                        <span className="file-name">
                          {fileName}

                        </span>
                    </label>
                </div>
                <br/>
                <button className="button is-dark is-capitalized is-fullwidth mt-1" type="submit">upload document</button>

            </form>

        </section>
    );
}

export default UploadDocument;

