// import logo from './logo.svg';
import './App.scss';
// import 'bulma/bulma.sass'
import React, {useReducer} from 'react';
import {listDocuments} from './services/S3';
import DocumentList from './views/DocumentList/DocumentList'
import HomePage from './views/HomePage/HomePage'
import UploadDocument from "./views/UploadDocument/UploadDocument";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import ErrorPage from "./views/ErrorPage/ErrorPage";
import { AppStateReducer, InitAppState, S3FolderNames} from "./store/Reducer";
import Login from "./views/Login/Login";
import {AppStateContext} from "./store/contexts";


const router = createBrowserRouter([
  {
    path: "/",
    element: <HomePage />,
    errorElement: <ErrorPage />,
    children:[
      {
        path: "/",
        element: <Login />,
        errorElement: <ErrorPage />
      },
      {
        path: "/InputDocument",
        element: <DocumentList folder={S3FolderNames.INPUT} documentListProvider={listDocuments}/>,
        errorElement: <ErrorPage />
      },
      {
        path: "/StagingDocument",
        element: <DocumentList folder={S3FolderNames.STAGING} documentListProvider={listDocuments}/>,
        errorElement: <ErrorPage />
      },
      {
        path: "/OutputDocument",
        element: <DocumentList folder={S3FolderNames.OUTPUT} documentListProvider={listDocuments} displayAsJobDetailsTable={true}/>,
        errorElement: <ErrorPage />
      },
      {
        path:"/UploadDocuments",
        element: <UploadDocument user={"alzamer"}/>,
        errorElement: <ErrorPage />
      }
    ]

  },

]);

function App() {
  const [appState, dispatch] = useReducer(AppStateReducer, InitAppState);
  // const appStateContext= useAppStateContext();
  return (
      <div className="App">
        <AppStateContext.Provider value={{ appState: appState, dispatch: dispatch } }>
          <RouterProvider router={router}  />
        </AppStateContext.Provider>
      </div>

  );
}

export default App;
