import {useAppStateContext} from "../store/contexts";
import {useNavigate} from "react-router-dom";
import {useEffect} from "react";

export function useLoginLogoutGuard(){
    const {appState} = useAppStateContext();
    const navigate = useNavigate();
    useEffect(() => {
        if(appState.loggedInUser){
            navigate("/UploadDocuments")
        }else{
            navigate("/")
        }
    }, [appState.loggedInUser]);
}