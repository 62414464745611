import {ListOutputDocumentsResponse, S3PresignedListResponse, S3PresignedPostResponse} from "../services/S3";
import {S3FolderNames} from "./Reducer";

export type ActionType =
    "LIST_FOLDER_PRESIGNED_URL_PREPARED"
    | "LIST_FOLDER_PRESIGNED_URL_EXPIRED"
    | "LIST_FOLDER_REQUESTED"
    | "LIST_FOLDER_COMPLETED"
    | "PRESIGNED_POST_INFO_PREPARED"
    | "PRESIGNED_POST_INFO_EXPIRED"
    | "LOGIN"
    | "LOGOUT"
;

export interface ListFolderPresignedUrlPreparedPayload {
    folder: S3FolderNames,
    s3PresignedListResponse: S3PresignedListResponse
}
export interface ListFolderExecutedPayload {
    folder: S3FolderNames,
    s3PresignedListResponse: ListOutputDocumentsResponse
}

export type Action ={
    type: ActionType,
    // payload:S3PresignedPostResponse | ListFolderPresignedUrlPreparedPayload | ListFolderExecutedPayload | S3FolderNames | string | null
} & (ListFolderPresignedUrlPreparedAction | ListFolderPresignedUrlExpiredAction | LoginAction | LogoutAction | ListFolderRequesteddAction | ListFolderCompletedAction | PresignedPostInfoPreparedAction | PresignedPostInfoExpiredAction);

type ListFolderPresignedUrlPreparedAction={
    type: "LIST_FOLDER_PRESIGNED_URL_PREPARED",
    payload: ListFolderPresignedUrlPreparedPayload
}
type ListFolderPresignedUrlExpiredAction={
    type: "LIST_FOLDER_PRESIGNED_URL_EXPIRED",
    payload: S3FolderNames
}
type LoginAction={
    type: "LOGIN",
    payload: string
}
type LogoutAction={
    type: "LOGOUT"
}
type ListFolderRequesteddAction={
    type: "LIST_FOLDER_REQUESTED",
    payload: S3FolderNames
}
type ListFolderCompletedAction={
    type: "LIST_FOLDER_COMPLETED",
    payload: ListFolderExecutedPayload
}
type PresignedPostInfoPreparedAction={
    type: "PRESIGNED_POST_INFO_PREPARED",
    payload: S3PresignedPostResponse
}
type PresignedPostInfoExpiredAction={
    type: "PRESIGNED_POST_INFO_EXPIRED"
}
export function createListFolderPresignedUrlPreparedAction(payload :ListFolderPresignedUrlPreparedPayload):Action{

    return {
        type: "LIST_FOLDER_PRESIGNED_URL_PREPARED",
        payload: payload
    }
}
export function createListFolderPresignedUrlExpiredAction(payload :S3FolderNames):Action{
    return {
        type: "LIST_FOLDER_PRESIGNED_URL_EXPIRED",
        payload: payload
    }
}
export function createLoginAction(payload :string):Action{
    return {
        type: "LOGIN",
        payload: payload
    }
}
export function createLogoutAction():Action{
    return {
        type: "LOGOUT",
    }
}

export function createListFolderRequesteddAction(payload :S3FolderNames):Action{

    return {
        type: "LIST_FOLDER_REQUESTED",
        payload: payload
    }
}
export function createListFolderCompletedAction(payload :ListFolderExecutedPayload):Action{

    return {
        type: "LIST_FOLDER_COMPLETED",
        payload: payload
    }
}
export function createPresignedPostInfoPreparedAction(payload :S3PresignedPostResponse):Action{
    return {
        type: "PRESIGNED_POST_INFO_PREPARED",
        payload: payload
    }
}
export function createPresignedPostInfoExpiredAction():Action{
    return {
        type: "PRESIGNED_POST_INFO_EXPIRED",
    }
}