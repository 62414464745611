import {Action} from "./actions";
import {createContext, useContext} from "react";
import {AppState} from "./Reducer";

export interface AppStateContextType{
    appState: AppState,
    dispatch: React.Dispatch<Action>
}

export const AppStateContext = createContext<AppStateContextType >({} as any);


export function useAppStateContext(){
    return useContext<AppStateContextType>(AppStateContext)
}