import React from 'react';
function Login() {

    return (
        <main className="container has-text-centered mt-6 notification">
            <h1 className="title is-1 is-capitalized">please login first</h1>
        </main>
    );
}

export default Login;
