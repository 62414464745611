import convert from 'xml-js';
import {S3FolderNames} from "../store/Reducer";
import {notEmpty} from "./Utils";
import {current} from "immer";


export async function getPresignedURL (folder:string ,user: string):Promise<S3PresignedListResponse>{
    var endpot2GeneratePresignedUrl =  `${process.env.REACT_APP_GENERATE_PRE_SIGNED_LIST}?prefix=${folder}/${user}/`;
    var endpot2GeneratePresignedResponse  = await fetch(endpot2GeneratePresignedUrl)
    return await endpot2GeneratePresignedResponse.json();
}

export async function listDocuments(presignedUrl: string) :Promise<ListOutputDocumentsResponse>{
    const response = await fetch( presignedUrl);
    const xml =  await response.text();
    const result =  convert.xml2json(xml, {compact: true});
    return JSON.parse(result);
}

export async function getPresignedPostUrl(user: string):Promise<S3PresignedPostResponse>{
    const endpot2GeneratePresignedUrl =  `${process.env.REACT_APP_GENERATE_PRE_SIGNED_POST}?prefix=${process.env.REACT_APP_INPUT_FOLDER}${user}/`;
    const endpot2GeneratePresignedResponse  = await fetch(endpot2GeneratePresignedUrl);
    return await endpot2GeneratePresignedResponse.json();
}

export interface S3PresignedListResponse{
    url:string,
    expiresInSeconds: number,
}

export interface S3PresignedPostResponse{
    url:string,
    expiresInSeconds: number,
    fields: S3PresignedPostResponseFields
}
interface S3PresignedPostResponseFields{
    [index: string]:string,
    bucket:string,
    key:string,
    policy:string,
    "X-Amz-Algorithm":string,
    "X-Amz-Credential":string,
    "X-Amz-Date":string,
    "X-Amz-Security-Token":string,
    "X-Amz-Signature":string,
}


interface xml2jsonElement{
    _text: string
}

export interface ListOutputDocumentsResponse{
    ListBucketResult: ListBucketResult
}
interface ListBucketResult{
    Contents: Content[] | Content,
    IsTruncated : xml2jsonElement,
    keyCount: xml2jsonElement,
    MaxKeys: xml2jsonElement,
    Name: xml2jsonElement,
    Prefix: xml2jsonElement
}
interface Content{
    ETag: xml2jsonElement,
    Key: xml2jsonElement,
    LastModified: xml2jsonElement,
    Size: xml2jsonElement,
    StorageClass: xml2jsonElement

}

interface JobEntryDetails{
    [index: string]: S3File | string,
    originalDocument:S3File,
    receiptConfirmation: S3File,
    rawResult: S3File,
    keyValueCsv: S3File,
    Id:string
}

interface JobDetails{
    [string: string]:JobEntryDetails
}
export function parseOutputFolderResults(list: ListOutputDocumentsResponse|undefined|null):JobEntryDetails[]{
    var temp= [list?.ListBucketResult.Contents].flat().filter(notEmpty).map(content => {
        let keyParts = content.Key._text.split("/");
        let entryType="originalDocument";
        if(keyParts[3]===("textract_raw_result.json")){
            entryType="rawResult"
        }else if(keyParts[3]==="key-value.csv"){
            entryType="keyValueCsv"
        }else if(keyParts[3]==="textract_receipt.json"){
            entryType="receiptConfirmation"
        }
        return {
            key: content.Key._text,
            folder: keyParts[0],
            user: keyParts[1],
            documentProcessingJobId:keyParts[2],
            entryType: entryType,
            entryFileName:keyParts[3]

        }as S3File;
    } );

    const map = temp.reduce((prev,current) =>{
        let entry = prev[current.documentProcessingJobId]??{};
        entry.Id = current.documentProcessingJobId;
        entry[current.entryType] = current;
        prev[current.documentProcessingJobId]=entry;
        return prev;
    },{} as JobDetails)

    const jobIds = Object.keys(map);
    return  jobIds.map(jobId => map[jobId]);

}

interface S3File{
    key: string,
    folder: S3FolderNames,
    user: string,
    documentProcessingJobId: string,
    entryType: string,
    entryFileName: string

}