import React from 'react';
// import logo from './logo.svg';
import './DocumentList.css';
import {getPresignedURL, listDocuments, ListOutputDocumentsResponse, parseOutputFolderResults} from '../../services/S3';
import {notEmpty} from "../../services/Utils";
import {S3FolderNames,} from "../../store/Reducer";
import {faRotateRight} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    createListFolderCompletedAction,
    createListFolderPresignedUrlExpiredAction,
    createListFolderPresignedUrlPreparedAction,
    createListFolderRequesteddAction
} from "../../store/actions";
import {useAppStateContext} from "../../store/contexts";

interface DocumentListProps{
    folder: S3FolderNames,
    documentListProvider: (folder: string) => Promise<ListOutputDocumentsResponse>,
    displayAsJobDetailsTable?: boolean
}
function DocumentList({folder, documentListProvider, displayAsJobDetailsTable=false}: DocumentListProps) {

    const {appState, dispatch}= useAppStateContext();

    async function loadListFolderPresignedUrl(user: string):Promise<string> {
        let presignedUrl=appState.s3Folders[folder];
        if(presignedUrl?.presignedListUrl){
            return presignedUrl.presignedListUrl;
        }

        const folderListPresignedUrl = await getPresignedURL(folder, user);
        const action = createListFolderPresignedUrlPreparedAction({
            folder: folder,
            s3PresignedListResponse: folderListPresignedUrl
        });
        dispatch(action);
        setTimeout(() => {
            dispatch(createListFolderPresignedUrlExpiredAction(folder));
        }, Math.max(folderListPresignedUrl.expiresInSeconds -
            60,0) * 1000);
        return folderListPresignedUrl.url;
    }

    async function reloadOutputDocs(){
        const user= appState.loggedInUser;
        if(!user){
            return;
        }
        dispatch(createListFolderRequesteddAction(folder))

        const preSignedUrl = await loadListFolderPresignedUrl(user);
        const result = await listDocuments(preSignedUrl);

        dispatch(createListFolderCompletedAction({folder:folder, s3PresignedListResponse:result}));
        // console.info(result)
        // setDoc(result)
    }

    const openFolder = appState.s3Folders[folder];
    console.info("is loading",openFolder?.loading)
    let docsdetails:React.JSX.Element = <></>;

    if(displayAsJobDetailsTable){
        const temp = parseOutputFolderResults(openFolder?.files);
        docsdetails =!openFolder?.files ?<></> : <div className="table-container">
            <table className="table is-striped is-narrow is-hoverable is-bordered is-primary">
                <thead>
                <tr>
                    <td>Id</td>
                    <td>Original Document</td>
                    <td>Receipt</td>
                    <td>Raw Result</td>
                    <td>CSV</td>
                </tr>
                </thead>
                <tbody>
                {temp.map(job => (
                    <tr>
                        <td>{job.Id}</td>
                        <td>{job.originalDocument.entryFileName} <input type={"hidden"} value={job.originalDocument.key}/></td>
                        <td>{job.receiptConfirmation.entryFileName}<input type={"hidden"} value={job.receiptConfirmation.key}/></td>
                        <td>{job.rawResult.entryFileName}<input type={"hidden"} value={job.rawResult.key}/></td>
                        <td>{job.keyValueCsv.entryFileName}<input type={"hidden"} value={job.keyValueCsv.key}/></td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    }else{
        const items = [openFolder?.files?.ListBucketResult.Contents]
            .flat()
            .filter(notEmpty)
            .map(content => <li key={content.Key._text}>{content.Key._text}</li>)

        docsdetails = <ol>{items}</ol>
    }


    return (
        <section className="DocumentsList">
            <h3>
                list of documents under {folder} folder:
            </h3>
            {/*<button className="button is-primary is-capitalized" onClick={reloadOutputDocs}>reload document</button>*/}
            <button className="button is-dark is-capitalized  my-2 is-fullwidth" onClick={reloadOutputDocs} >
                <span className="icon">
                  <i className="fab fa-github"></i>
                    <FontAwesomeIcon icon={faRotateRight} spin={openFolder?.loading}/>
                </span>
                <span>(re)load document</span>
            </button>
            {docsdetails}

        </section>
    );
}

export default DocumentList;

